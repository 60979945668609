import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import globalMixin from "@/lib/globalMixin";
import '@vant/touch-emulator';
import VueRouter from "vue-router";
//修复Navigation Cancelled问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function(location, onResolve, onReject) {
  if (onResolve || onReject)
    originalPush.call(this, location, onResolve, onReject)
  originalPush.call(this, location).catch(err => err)
}

Vue.prototype.env = process.env
Vue.config.productionTip = false
Vue.prototype.$env = process.env
Vue.prototype.$isMobile = function () {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  return flag !== null && document.body.clientWidth < 768;
}
Vue.mixin(globalMixin)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
