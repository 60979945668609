import Vue from 'vue'
import VueRouter from 'vue-router'
import storage from "@/lib/storage";
import {Toast} from 'vant';
import {BASE_URL} from "@/lib/baseUrl";
import store from "@/store/index"
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: () => import('@/views/Home.vue')
    },
    {
        path: '/shop',
        component: () => import('@/views/Shop/Index.vue')
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            allowGuest: true,
        },
        component: () => import('@/views/Login.vue')
    },
    {
        path: '/test',
        component: () => import('@/views/Test.vue')
    },
    {
        path: '/changelog',
        component: () => import('@/views/ChangeLog.vue')
    },
    {
        path: '/cg',
        component: () => import('@/views/CardGuess/IndexV2.vue')
    },
    {
        path: '/commands/help',
        component: () => import('@/views/Commands/Help.vue')
    },
    {
        path: '/user/info',
        component: () => import('@/views/User/UserInfo.vue')
    },
    {
        path: '/user/exchange',
        component: () => import('@/views/User/Exchange.vue')
    },
    {
        path: '/guestbook',
        component: () => import('@/views/Guestbook.vue')
    },

    //派系
    {
        path: '/pie',
        component: () => import('@/views/Pie/Index.vue')
    },
    {
        path: '/pie/:id',
        component: () => import('@/views/Pie/Detail.vue')
    },

    //MC
    {
        path: '/minecraft',
        component: () => import('@/views/Minecraft/Index.vue')
    },

    //粉卡
    {
        path: '/pcard/admin/create',
        component: () => import('@/views/Card/Create.vue')
    },
    {
        path: '/pcard/admin/update/:id',
        component: () => import('@/views/Card/Create.vue')
    },
    {
        path: '/pcard/admin/list',
        component: () => import('@/views/Card/List.vue')
    },
    {
        path: '/pcard/admin/test',
        component: () => import('@/views/Card/Test.vue')
    },

    //SRVPRO
    {
        path: '/srvpro',
        component: () => import('@/views/SRVPRO/Index.vue')
    },
    {
        path: '/srvpro/create',
        component: () => import('@/views/SRVPRO/Create.vue')
    },
    {
        path: '/srvpro/duel',
        component: () => import('@/views/SRVPRO/DuelSearch.vue')
    },
    {
        path: '/srvpro/adminReport',
        component: () => import('@/views/SRVPRO/AdminReports.vue')
    },
    {
        path: '/srvpro/userDuel',
        component: () => import('@/views/SRVPRO/UserDuelSearch.vue')
    },
    {
        path: '/srvpro/deck',
        component: () => import('@/views/SRVPRO/DeckSearch.vue')
    },

    //分类游戏
    {
        path: '/cardTag/create',
        component: () => import('@/views/CardTag/Create.vue')
    },
    {
        path: '/cardTag',
        component: () => import('@/views/CardTag/Index.vue')
    },
    {
        path: '/cardTag/my',
        component: () => import('@/views/CardTag/My.vue')
    },
    {
        path: '/cardTag/update/:id',
        component: () => import('@/views/CardTag/Create.vue')
    },


    //Donation
    {
        path: '/donation/:target',
        component: () => import('@/views/Donation/Index.vue')
    },

    //Admin
    {
        path: '/admin/review',
        component: () => import('@/views/Admin/Review/Index')
    },
    {
        path: '/admin/sprite',
        component: () => import('@/views/Admin/Sprite/Index')
    },
    {
        path: '/admin/env',
        component: () => import('@/views/Admin/Env/Index')
    },
    {
        path: '/admin/drawing',
        component: () => import('@/views/Admin/Drawing/Index')
    },


    //接龙
    {
        path: '/chain',
        component: () => import('@/views/ChainServer/ChainServer'),
        meta: {
            allowGuest: true,
        },
    },

]


const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})
const syncUserInfo = (onSuccess, onError = null) => {
    const token = storage.getItem('vcs_token')
    if (token) {
        fetch(BASE_URL + '/auth/me', {
            method: 'GET',
            headers: {
                "Accept": "text/json",
                "Content-Type": "text/json;charset=UTF-8",
                "VAUTH": "HECTIV",
                "Authorization": 'Bearer ' + token
            }
        })
            .then(res => res.json())
            .then(res => {
                if (res.status === 'success') {
                    store.commit("setUserInfo", res.data)
                    onSuccess(res)
                } else {
                    storage.deleteItem('vcs_token')
                    onError()
                }
            })
            .catch(err => {
                storage.deleteItem('vcs_token')
                onError()
            })
    } else {
        onError()
    }
}

router.beforeEach((to, from, next) => {
    console.log(to)
    //token获取
    if (to.query.vtoken) {
        // console.log(to.query.vtoken)
        storage.setItem('vcs_token', to.query.vtoken)
    }
    let needAuthCheck = true
    if (to.meta.hasOwnProperty('allowGuest') && to.meta.allowGuest) {
        needAuthCheck = false
    }
    if (to.query.noAuthCheck) {
        needAuthCheck = false
    }
    syncUserInfo((res) => {
        next()
    }, () => {
        if (needAuthCheck) {
            let nextParam = {
                path: '/login',
            }
            let toast = false;
            if (to.fullPath !== '/') {
                toast = true;
                nextParam.query = {redirect: to.fullPath}
            }
            next(nextParam);
            if (toast) Toast.fail("请先登录")
        } else {
            next()
        }
    })
})

export default router
